import React from 'react'
import PropTypes from 'prop-types'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import ClearIcon from '@material-ui/icons/Clear'
import { getReferencesTitle } from '@youversion/utils'
import BibleReferenceSelector from 'components/ui/bible-reference-selector'
import { bibleESVBooksData, bibleESVDataJson } from 'utils/bible-book-data'
import { compressRelatedBibleReferences } from 'utils/compress-bible-references'

import {
  Box,
  Chip,
  CircularProgress,
  Fade,
  IconButton,
  LinearProgress,
  Typography,
  makeStyles,
} from '@material-ui/core'
import useBibleReferenceParser from '../../../../hooks/use-bible-reference-parser'

const useStyles = makeStyles((theme) => ({
  hide: {
    visibility: 'hidden',
  },
  btnEditRef: {
    opacity: '0%',
    transition: 'opacity 0.1s ease-in',
  },
  refActions: {
    minHeight: 48,
  },
  references: {
    width: '100%',
    '&:hover $btnEditRef': {
      opacity: '100%',
    },
  },
}))

const getReferenceWithTitle = (reference) => {
  return {
    title: getReferencesTitle({
      bookList: bibleESVBooksData,
      usfmList: reference,
    }).title,
    usfm: reference,
  }
}

export default function VideoReferences({
  references,
  handleReferencesUpdate,
}) {
  const [mutableReferences, setMutableReferences] = React.useState(() =>
    references?.map((reference) => getReferenceWithTitle(reference)),
  )
  // TODO: I would like to change this from compressed to currentlySelectedReferences.
  const [compressedReferences, setCompressedReferences] = React.useState([])
  const [editModeEnabled, setEditModeEnabled] = React.useState(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)
  const [bibleReferences, setBibleReferences] = React.useState('')

  const { bookData, chapterStart, chapterEnd } = useBibleReferenceParser(
    bibleReferences,
  )

  const classes = useStyles()

  function handleAddReference(reference) {
    const newReferences = []
    // check if is verse or chapter
    const firstOfUsfm = reference.split('+')[0]
    const isChapterRegex = /^[1-3]?[A-z]{2,3}.\d+$/
    if (reference.includes('+') && isChapterRegex.test(firstOfUsfm)) {
      // chapters do split on + delimiters
      // But if all the chapters are selected, we will return the Book name with
      if (chapterEnd && chapterEnd < bookData.max_num_of_chapters) {
        newReferences.push(
          ...reference.split('+').map((chapterReference) => {
            return getReferenceWithTitle(chapterReference)
          }),
        )
      } else if (chapterEnd && chapterEnd === bookData.max_num_of_chapters) {
        newReferences.push(
          getReferenceWithTitle(
            `${bookData.usfm}.${chapterStart}-${chapterEnd}`,
          ),
        )
      }
    } else {
      // verses don't split on + delimiters
      newReferences.push(getReferenceWithTitle(reference))
    }

    // updates references and removes duplicates
    setMutableReferences((prevReferences) => {
      // references without duplicates (source: https://stackoverflow.com/a/36744732)
      const updatedReferences = [...prevReferences, ...newReferences].filter(
        (referenceObj, index, self) =>
          index ===
          self.findIndex((t) => {
            return (
              t.title === referenceObj.title && t.usfm === referenceObj.usfm
            )
          }),
      )
      return updatedReferences
    })
    setHasUnsavedChanges(true)
  }

  function handleDeleteReference(index) {
    setMutableReferences((prevReferences) => {
      const updatedReferences = [...prevReferences]
      updatedReferences.splice(index, 1)
      return updatedReferences
    })
    setHasUnsavedChanges(true)
  }

  function clearUpdates() {
    setMutableReferences(
      references?.map((reference) => getReferenceWithTitle(reference)),
    )
    setHasUnsavedChanges(false)
    setEditModeEnabled(false)
  }

  async function handleSave() {
    setIsSaving(true)
    await handleReferencesUpdate(mutableReferences.map((refObj) => refObj.usfm))
    setIsSaving(false)
    setHasUnsavedChanges(false)
    setEditModeEnabled(false)
  }

  React.useEffect(() => {
    if (mutableReferences.length > 0 && !editModeEnabled) {
      const compressedBibleReferences = compressRelatedBibleReferences(
        mutableReferences,
        bibleESVDataJson,
      )
      setCompressedReferences(compressedBibleReferences)
    } else if (!editModeEnabled) {
      setCompressedReferences([])
    }
  }, [mutableReferences, editModeEnabled])

  if (!references) return <LinearProgress />

  return (
    <>
      <Box className={classes.references}>
        {!editModeEnabled ? (
          <Box
            alignItems="center"
            className={classes.refActions}
            display="flex"
          >
            <Typography color="textPrimary" variant="h3">
              Bible References
            </Typography>
            <IconButton
              aria-label="edit references"
              className={classes.btnEditRef}
              onClick={() => setEditModeEnabled(true)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Box>
        ) : (
          <Fade in={true}>
            <Box display="flex" justifyContent="space-between">
              <Box alignItems="center" display="flex" mb={1}>
                {editModeEnabled ? (
                  // helps me enable auto-focus
                  <BibleReferenceSelector
                    bibleReferences={bibleReferences}
                    disabled={isSaving}
                    onEnter={handleAddReference}
                    setBibleReferences={setBibleReferences}
                  />
                ) : null}
              </Box>
              <Box>
                {hasUnsavedChanges ? (
                  <IconButton
                    aria-label="add a reference"
                    color="primary"
                    disabled={isSaving}
                    onClick={handleSave}
                  >
                    {!isSaving ? (
                      <SaveIcon fontSize="small" />
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </IconButton>
                ) : null}
                <IconButton
                  aria-label="cancel updating references"
                  disabled={isSaving}
                  onClick={clearUpdates}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          </Fade>
        )}

        <Box display="flex" flexWrap="wrap">
          {/* Section to Edit Container */}
          {editModeEnabled
            ? mutableReferences.map((mutableReference, index) => {
                const onDeleteClicked = () =>
                  editModeEnabled ? handleDeleteReference(index) : null
                return (
                  <Fade in={true} key={mutableReference.title}>
                    <Box mb={1} mr={1} width="100%">
                      <Chip
                        label={mutableReference.title}
                        onDelete={onDeleteClicked}
                      />
                    </Box>
                  </Fade>
                )
              })
            : compressedReferences.map((compressedReference) => {
                return (
                  <Fade in={true} key={compressedReference}>
                    <Box mb={1} mr={1} width="100%">
                      <Chip label={compressedReference} />
                    </Box>
                  </Fade>
                )
              })}
          {/* No Reference Has been added to the video*/}
          {mutableReferences.length === 0 ? (
            <Typography color="textSecondary" component="p" variant="subtitle1">
              Consider adding a Bible reference.
            </Typography>
          ) : null}
        </Box>
      </Box>
    </>
  )
}

VideoReferences.propTypes = {
  references: PropTypes.arrayOf(PropTypes.string),
  handleReferencesUpdate: PropTypes.func.isRequired,
}

VideoReferences.defaultProps = {
  references: [],
}
