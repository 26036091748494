import React from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import {
  Box,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import DeleteIcon from '@material-ui/icons/Delete'
import { getParamsFromProps, statusTypes, urlReplacer } from 'utils'
import imgPeopleCelebrating from 'assets/img/people-celebrating.png'
import VideoReferences from 'components/views/video/video-references'
import ButtonDialog from 'components/ui/button-dialog'
import { FriendlyError } from 'components/ui/friendly-error-page'
import SecondaryAppBar from 'components/layout/secondary-app-bar'
import { LanguageAddCard, LanguageEditCard } from 'components/ui/language-cards'
import { AlertBar, AlertProvider, useAlert } from 'components/layout/alert-bar'
import LazyImage from 'components/ui/lazy-image'
import { VideoCollectionList } from 'components/views/video/collection-list'
import { useFriendlyErrorPage } from 'contexts/friendly-error-message-context'
import useAsync from 'hooks/useAsync'
import Video from 'tupos/models/video'
import Publisher from 'tupos/models/publisher'
import Configuration from 'tupos/models/configuration'
import Languages from 'tupos/models/languages'
import Collection from 'tupos/models/collections'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  container: {
    maxWidth: 980,
    margin: 'auto',
    padding: theme.spacing(2),
  },
  content: {
    maxWidth: 960,
    margin: 'auto',
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  buttonSpacing: {
    margin: '0 auto',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  iconRight: {
    verticalAlign: 'text-top',
  },
}))

const useSkeletonStyles = makeStyles((theme) => ({
  loadingSecondaryAppBar: {
    width: '100%',
    height: 56, // height on mobile
    [theme.breakpoints.up('md')]: {
      height: 64, // height on desktop
    },
  },
  container: {
    maxWidth: 980,
    margin: 'auto',
    padding: theme.spacing(2),
  },
  content: {
    maxWidth: 960,
    margin: 'auto',
    padding: theme.spacing(2),
  },
}))

function VideoDetailSkeleton() {
  const classes = useSkeletonStyles()
  return (
    <>
      <Skeleton className={classes.loadingSecondaryAppBar} variant="rect" />
      <Grid className={classes.container} container={true}>
        <Grid
          alignItems="center"
          className={classes.content}
          container={true}
          spacing={1}
        >
          <Grid item={true} xs={1}>
            <Skeleton height="36px" variant="rect" width="36px" />
          </Grid>
          <Grid item={true}>
            <Skeleton variant="text" width="150px" />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

function getPublisherImageUrl(config, publisher, language) {
  const url = config.imageUrls.publisherLogo
  return urlReplacer(url, {
    size: '151',
    id: publisher.id,
    language,
  })
}

async function deleteAllVideosFromId(videoId, videoLanguages) {
  const deleteAllVideosPromises = videoLanguages.map((language) => {
    const videoClass = Video.toClass({
      json: { id: videoId },
      language,
    })
    return videoClass.delete()
  })
  return Promise.all(deleteAllVideosPromises)
}

/**
 * # Video Detail Page.
 *
 * Container for displaying existing videos per id, updating videos, and for
 * creating new videos of the same id of a different language.
 */
function VideoDetailPage() {
  const history = useHistory()
  const location = useLocation()
  const { id: videoId } = useParams()

  const [selectedVideo, setSelectedVideo] = React.useState()
  const [publisher, setPublisher] = React.useState()
  const [config, setConfig] = React.useState()
  const [languages, setLanguages] = React.useState()
  const [collections, setCollections] = React.useState([])
  const [hasDeletedAllVideos, setHasDeletedAllVideos] = React.useState(false)
  const [
    isVideoDisplayedInReaderApp,
    setVideoDisplayedInReaderApp,
  ] = React.useState(true)

  const selectedLanguage = getParamsFromProps({ location }, 'lang', 'en')

  const { throwFriendlyErrorPage } = useFriendlyErrorPage()
  const { throwAlert } = useAlert()

  const classes = useStyles()

  const loadData = React.useCallback(async () => {
    try {
      const languagesResponse = await Languages.get('video', videoId)
      const videoResponse = await Video.get(videoId, selectedLanguage)
      const publisherResponse = await Publisher.get(
        videoResponse.publisherId,
        'false',
        // changed from default of 'en' to `selectedLanguage` just in case
        // publisher is only available in another language besides English
        selectedLanguage,
      )
      const configResponse = await Configuration.get()
      const isVerseActionPickerInDisallowed = videoResponse.disallowedContexts.includes(
        'verse_action_picker',
      )
      if (isVerseActionPickerInDisallowed) {
        setVideoDisplayedInReaderApp(false)
      } else {
        setVideoDisplayedInReaderApp(true)
      }

      setLanguages(languagesResponse)
      setSelectedVideo(videoResponse.toObject())
      setPublisher(publisherResponse)
      setConfig(configResponse)
    } catch (e) {
      throwFriendlyErrorPage({
        message: `Failed to load data for video with id (${videoId}).`,
      })

      return false // value passed to isReady by useAsync
    }

    return true // value passed to isReady by useAsync
  }, [throwFriendlyErrorPage, videoId, selectedLanguage])

  React.useEffect(() => {
    const fetchCollections = async () => {
      try {
        const collectionsResponse = await Collection.getCollection({
          videoId,
          language: selectedLanguage,
        })
        if (collectionsResponse?.rows.length > 0) {
          setCollections(collectionsResponse.rows)
        } else {
          setCollections([])
        }
      } catch {
        setCollections([])
      }
    }
    fetchCollections()
  }, [videoId, selectedLanguage])

  const { value: isReady } = useAsync(loadData, true)

  const addNewVideoLanguage = async ({ object: json, language }) => {
    try {
      if (typeof json !== 'object')
        throw new Error('addNewVideoLanguage failed to receive a video object.')

      // convert video object to class instance
      const videoInstance = Video.toClass({ json, language })

      // create new video language
      await videoInstance.createVideoLanguage()

      // add new video to the top of the list
      setLanguages((prevLangs) => [language, ...prevLangs])
    } catch ({ message }) {
      throwAlert({
        type: statusTypes.ERROR,
        id: `video_add_error_${language}`,
        message: `Failed to add the (${language}) video. ${message}`,
      })
    }
  }

  const handleDelete = async (language) => {
    try {
      const videoInstance = Video.toClass({ json: selectedVideo, language })
      await videoInstance.delete()
      setLanguages((prevLangs) => {
        const updatedLangs = prevLangs.filter(
          (lang) => lang !== videoInstance.language,
        )

        // if last video was deleted, let the admin know
        if (updatedLangs.length === 0) {
          setHasDeletedAllVideos(true)
          setTimeout(() => history.replace('/videos/'), 2500)
        } else {
          throwAlert({
            type: statusTypes.SUCCESS,
            id: `video_delete_success_${language}`,
            message: `Successfully deleted (${language}) video.`,
            enableFuse: true,
          })
        }

        return updatedLangs
      })
    } catch ({ message }) {
      throwAlert({
        type: statusTypes.ERROR,
        id: `video_delete_error_${language}`,
        message: `Failed to delete video. ${message}`,
      })
    }
  }

  const onUpdate = async ({ object: json, language }) => {
    try {
      const videoInstance = Video.toClass({ json, language })
      await videoInstance.updateVideo()
    } catch (error) {
      throwAlert({
        type: statusTypes.ERROR,
        id: `video_update_error_${language}`,
        message: `There was an error updating the (${language}) video.`,
      })
    }
  }

  async function updateReferences(updatedReferences) {
    try {
      const videoInstance = Video.toClass({
        json: {
          id: videoId,
          references: updatedReferences,
        },
      })
      await videoInstance.updateReferences()

      // update the refs now!
      setSelectedVideo((prevVideo) => {
        const prevVideoClone = { ...prevVideo }
        prevVideoClone.references = updatedReferences
        return prevVideoClone
      })

      throwAlert({
        type: statusTypes.SUCCESS,
        id: 'video_refs_updated',
        message: 'Video references updated!',
        enableFuse: true,
      })
    } catch (error) {
      throwAlert({
        type: statusTypes.ERROR,
        id: 'video_ref_update_error',
        message: "There was an error updating the video's Bible references.",
      })
    }
  }

  async function onCheckedDisplayInReader(event) {
    try {
      if (!event.target.checked) {
        const videoInstance = Video.toClass({
          json: {
            id: videoId,
            disallowed_contexts: ['verse_action_picker'],
            language: selectedLanguage,
          },
        })
        await videoInstance.updateDisplayingReaderInApp()
        setVideoDisplayedInReaderApp(false)
      } else {
        const videoInstance = Video.toClass({
          json: {
            id: videoId,
            disallowed_contexts: [],
            language: selectedLanguage,
          },
        })
        await videoInstance.updateDisplayingReaderInApp()
        setVideoDisplayedInReaderApp(true)
      }

      throwAlert({
        type: statusTypes.SUCCESS,
        id: 'display_in_updated',
        message: 'Updating displaying reader in the app was successful!',
        enableFuse: true,
      })
    } catch (e) {
      throwAlert({
        type: statusTypes.ERROR,
        id: 'display_in_updated',
        message: 'There was an error updating displaying reader in the app.',
      })
    }
  }

  async function handleVideoDeleteAll() {
    try {
      await deleteAllVideosFromId(videoId, languages)
    } catch ({ message }) {
      throwAlert({
        type: statusTypes.ERROR,
        id: 'video_delete_all_error',
        message: `Failed to delete videos. ${message}. Video may be attached to collection.`,
      })
      return
    }
    setHasDeletedAllVideos(true)
    setTimeout(() => history.replace('/videos/'), 2500)
  }

  if (hasDeletedAllVideos)
    return (
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - 64px)"
        justifyContent="center"
        width="100%"
      >
        <FriendlyError
          imageCredit="Illustrations from https://www.ls.graphics/"
          imageHeight={347}
          imageSrc={imgPeopleCelebrating}
          imageWidth={306}
          message="You will be redirected to the Videos page momentarily."
          title="Videos Successfully Deleted"
        />
      </Box>
    )

  if (!isReady) return <VideoDetailSkeleton />

  if (isReady) {
    // Back URL Routes
    const publisherImageUrl = getPublisherImageUrl(
      config,
      publisher,
      selectedLanguage,
    )

    const deleteVideoButton = (
      <ButtonDialog
        actionComponent={
          <Tooltip title="Delete this video in all available languages">
            <IconButton
              aria-label="delete all videos"
              className={classes.margin}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        }
        dialogConfirmText="Delete Videos"
        dialogDenyText="Cancel"
        dialogPrompt="Delete this video in all available languages?"
        handleDialogConfirm={handleVideoDeleteAll} // do nothing, just close the dialog
        handleDialogDeny={() => {}}
      />
    )

    return (
      <>
        <SecondaryAppBar
          actionComponent={deleteVideoButton}
          backComponent={
            <IconButton onClick={history.goBack}>
              <ArrowBack />
            </IconButton>
          }
          subtitle={selectedVideo.title}
          title="Video"
        />
        <Grid className={classes.container} container={true}>
          {/* Publisher */}
          <Grid
            alignItems="center"
            className={classes.content}
            container={true}
            spacing={1}
          >
            <Grid item={true} xs={1}>
              <LazyImage
                alt={publisher.name}
                height={36}
                src={publisherImageUrl}
                width={36}
              />
            </Grid>
            <Grid item={true}>
              <Link
                className={classes.link}
                to={`/publishers/${publisher.id}?lang=${selectedLanguage}`}
              >
                <Typography>
                  {publisher.name}
                  <ChevronRightIcon
                    className={classes.iconRight}
                    fontSize="small"
                  />
                </Typography>
              </Link>
            </Grid>
          </Grid>

          <Grid className={classes.content} container={true}>
            {/* References */}
            <Grid item={true} sm={6} xs={12}>
              <VideoReferences
                handleReferencesUpdate={updateReferences}
                references={selectedVideo.references}
              />
            </Grid>

            <Grid item={true} sm={6} xs={12}>
              <VideoCollectionList
                collections={collections}
                language={selectedLanguage}
              />
            </Grid>
          </Grid>
          <Grid className={classes.content} container={true} justify="center">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              width="100%"
            >
              <Box>
                <Typography color="textPrimary" variant="h3">
                  Display Video In Reader App
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Switch
                  checked={isVideoDisplayedInReaderApp}
                  onChange={onCheckedDisplayInReader}
                />
                <Typography color="textSecondary" variant="h3">
                  {isVideoDisplayedInReaderApp ? 'Visible' : 'Hidden'}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* Add new video language */}
          <Grid className={classes.content} container={true} justify="center">
            <LanguageAddCard
              initialObject={selectedVideo}
              languages={languages}
              onSave={addNewVideoLanguage}
              type="video"
            />
          </Grid>

          <Grid className={classes.content} container={true} direction="column">
            {languages.map((lang) => (
              <LanguageEditCard
                id={`${selectedVideo.id}`}
                isExpanded={
                  selectedLanguage === lang &&
                  selectedVideo.status !== 'unready'
                }
                key={`${publisher.id}_${lang}`}
                language={lang}
                onDelete={() => handleDelete(lang)}
                onSave={onUpdate}
                saveButtonLabel="Update"
                type="video"
              />
            ))}
          </Grid>
        </Grid>
      </>
    )
  }

  return null
}

function VideoDetail(props) {
  return (
    <AlertProvider>
      <AlertBar />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <VideoDetailPage {...props} />
    </AlertProvider>
  )
}

export default VideoDetail
